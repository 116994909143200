import { ChakraProvider, Box, theme, Container } from "@chakra-ui/react";
import Contact from "./Contact";
import FAQs from "./FAQs";
import Footer from "./Footer";
import Hero from "./Hero";
import OurStory from "./OurStory";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Hero />

      {/* Our Story section */}
      <OurStory />

      <Container maxW={"4xl"}>
        {/* FAQs */}
        <FAQs />
      </Container>

      {/* contact */}
      <Contact />

      {/* footer */}
      <Footer />
    </Box>
  </ChakraProvider>
);
