import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Heading,
  AccordionIcon,
} from "@chakra-ui/react";
import {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
  Key,
} from "react";

type FAQProps = {
  question:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
  answer:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
};

const Faq = ({ faqs }: any) => {
  return (
    <Accordion defaultIndex={[0]} allowToggle>
      {faqs.map((faq: FAQProps, index: number | Key | any) => (
        <AccordionItem key={index} py={2}>
          {({ isExpanded }) => (
            <>
              <Heading as="h2">
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight={isExpanded ? 800 : 500}
                  >
                    {faq.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel textAlign="left">{faq.answer}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default Faq;
