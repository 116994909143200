import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  VStack,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { BsPerson } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type InputProps = {
  name: string;
  email: string;
  message: string;
};

type ErrorProps = {
  status: boolean;
  error: string;
};

export default function Waitlist() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const form = useRef<HTMLFormElement | null | any>();
  const [error, setError] = useState<ErrorProps>({
    status: false,
    error: "",
  });
  const [input, setInput] = useState<InputProps>({
    name: "",
    email: "",
    message: "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;
    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  };

  const handleSubmit = () => {
    if (!input.name || !input.email) {
      setError({
        status: true,
        error: "All fields are required",
      });
      return;
    }

    emailjs
      .sendForm(
        "service_uwvyxdv",
        "template_k3zu6be",
        form.current,
        "wWfrEFxWlAow8uY7P"
      )
      .then(
        (result) => {
          toast.success(
            "Waitlist details have been sent to the team. We'll get back to you on the provided email :)",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        },
        (error) => {
          console.log("result:..........", error);
        }
      );

    // close modal
    setTimeout(() => {
      onClose();
    }, 6500);
  };

  return (
    <>
      <Button
        colorScheme={"green"}
        bg={"green.400"}
        rounded={"full"}
        px={6}
        _hover={{
          bg: "green.500",
        }}
        onClick={onOpen}
      >
        Join Waitlist
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Join the Waitlist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box bg="white" borderRadius="lg" fontSize="sm">
              <Box m={4} color="#0B0E3F">
                <VStack spacing={1}>
                  <form
                    ref={form}
                    onSubmit={handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <FormControl
                      id="name"
                      isRequired
                      isInvalid={error.status && input.name === ""}
                    >
                      <FormLabel>Your Name</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BsPerson color="gray.800" />}
                        />
                        <Input
                          type="text"
                          size="md"
                          placeholder="John Doe"
                          name="name"
                          onChange={handleOnChange}
                        />
                      </InputGroup>
                      {error.status && input.name === "" && (
                        <FormErrorMessage>Name is required</FormErrorMessage>
                      )}
                    </FormControl>
                    <br />
                    <FormControl
                      id="email"
                      isRequired
                      isInvalid={error.status && input.email === ""}
                    >
                      <FormLabel>Work Email Address</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<MdEmail color="gray.800" />}
                        />
                        <Input
                          type="email"
                          size="md"
                          placeholder="john.doe@nicanor.me"
                          onChange={handleOnChange}
                          name="email"
                          id="email"
                        />
                      </InputGroup>
                      {error.status && input.email === "" ? (
                        <FormErrorMessage>
                          Email address is required
                        </FormErrorMessage>
                      ) : (
                        <FormHelperText>
                          We'll never share your email.
                        </FormHelperText>
                      )}
                    </FormControl>
                    <br />
                    <FormControl id="message">
                      <FormLabel>Additional Message</FormLabel>
                      <Textarea
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: "gray.300",
                        }}
                        placeholder="Add any other information you would love to share with us"
                        onChange={handleOnChange}
                        name="message"
                      />
                    </FormControl>
                    <br />

                    <Button onClick={onClose} mr={4}>
                      Close
                    </Button>
                    <Button
                      variant="solid"
                      bg="green.400"
                      color="white"
                      onClick={handleSubmit}
                      _hover={{ variant: "solid" }}
                    >
                      Sign me up
                    </Button>
                  </form>
                </VStack>
                <ToastContainer />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
