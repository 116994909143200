import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";

export default function Contact() {
  return (
    <Container maxW="full" py={["4rem", 0]} centerContent overflow="hidden">
      <Flex
        width={["100%", "-webkit-fit-content"]}
        bg="green.400"
        color="white"
        borderRadius="lg"
        m={{ sm: 4, md: 16, lg: 10 }}
        p={{ sm: 5, md: 5, lg: 16 }}
      >
        <Box p={4}>
          <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
            <WrapItem>
              <Box>
                <Heading>Contact Us</Heading>
                <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.200">
                  We always there for you
                </Text>
                <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <VStack pl={0} spacing={3}>
                    <Button
                      size="md"
                      height="48px"
                      width="200px"
                      variant="ghost"
                      color="#DCE2FF"
                      _hover={{ border: "none" }}
                      leftIcon={<MdPhone color="#1970F1" size="20px" />}
                      role="textbox"
                    >
                      +254 114 660 017
                    </Button>
                    <Button
                      size="md"
                      height="48px"
                      width="200px"
                      variant="ghost"
                      color="#DCE2FF"
                      _hover={{ border: "none" }}
                      leftIcon={<MdEmail color="#1970F1" size="20px" />}
                    >
                      contact@converso.ai
                    </Button>
                    <Button
                      size="md"
                      height="48px"
                      width="200px"
                      variant="ghost"
                      color="#DCE2FF"
                      _hover={{ border: "2px solid #1C6FEB" }}
                      leftIcon={<MdLocationOn color="#1970F1" size="20px" />}
                    >
                      Nairobi, Kenya
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </Flex>
    </Container>
  );
}
