import { Box, Heading } from "@chakra-ui/react";
import Faq from "./Faq";

const faqs = [
  {
    question: "What's Converso.ai?",
    answer:
      "Converso is an AI-powered and human-centric customer service platform that offers chatbot product solution for different businesses around the world. It's a unique solution to improve their customer engagement and satisfaction.",
  },
  {
    question: "Who can sign up to the waitlist?",
    answer:
      "The waitlist is open to anyone who is interested in trying out a chatbot AI solution. By signing up to the waitlist you agree to be part of the beta enrollment.",
  },
  {
    question: "What are the unique features for converso.ai?",
    answer:
      "Converso chatbot can interact in multiple languages including African languages, as well as integrations with popular third-party platforms such as WhatsApp Business, Instagram, etc to create a scalable and affordable solutions to improve businesses.",
  },
  {
    question: "What happens when I join Waitlist? When will I get access?",
    answer:
      "Converso ai is currently in development and soon will be starting to gather feedback from customers. We'll start gradually inviting more customers to join the beta in the next few months.",
  },
  {
    question: "Will my entire workspace or business be signed up?",
    answer:
      "Yes, once available, beta access will be available for your whole workspace.",
  },
  {
    question: "What languages does the AI bot work with?",
    answer:
      "Converso ai works with multiple languages accross the work including native languages such as Afrikaans, swahili, Bantu, nilotic languages, and many more..",
  },
  {
    question: "How much does it cost?",
    answer:
      "Beta will be available free of charge. We will provide pricing breakdown once we share the general release.",
  },
  {
    question: "How will my data be used?",
    answer:
      "We never share your data without your consent. We use the waitlist data shared to give access to beta release and for communications purposes only.",
  },
];

const FAQs = () => {
  return (
    <Box my={["2rem", "4rem"]}>
      <Heading>FAQs</Heading>
      <Faq faqs={faqs} />
    </Box>
  );
};

export default FAQs;
